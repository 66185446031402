<template>
  <v-container fluid id="filter_wrap">
    <ErrorBox :error="x.error" />
    <v-layout row pt-3>
      <v-flex xs2 md4>
        <v-select
          v-model="searchTypes"
          :items="searchItems"
          label="Search Type"
          item-text="status"
          class="pt-0 currencyTitle pppp"
          item-value="abbr"
          persistent-hint
          @change="searchByType()"
          height="10"
          return-object
          outline
        ></v-select>
      </v-flex>
      <v-flex xs2 md3>
        <v-text-field
          height="55px"
          class="gap Bottom_2px"
          inputmode="search"
          type="search"
          label="Search here.."
          outline
          :disabled="!searchTypes"
          single-line
          v-model="search"
          @keyup.enter="searchAnything"
          @click:append="searchAnything"
          append-icon="search"
        ></v-text-field>
      </v-flex>
      <v-flex
        xs3
        pl-2
        pr-2
        style="padding-top: 18px"
        class="wrapper"
        id="accountDate"
      >
        <ejs-daterangepicker
          :placeholder="waterMark"
          v-model="dateRange2"
        ></ejs-daterangepicker>
      </v-flex>
      <!-- <v-flex xs2 md2>
        <span class="muted"></span>
        <v-menu
          ref="FilterDateBool"
          lazy
          v-model="FilterDateBool"
          :close-on-content-click="false"
          transition="scale-transition"
          full-width
          :nudge-right="40"
          min-width="290px"
          :return-value.sync="startDate"
        >
          <v-text-field
            slot="activator"
            :label="'Start date'"
            required
            v-model="startDate"
            prepend-icon="event"
            readonly
          ></v-text-field>
          <v-date-picker
            v-model="startDate"
            class="minHeight"
            @change="
              $refs.FilterDateBool.save(startDate);
              searchByDate();
            "
            no-title
            scrollable
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs2 pr-2>
        <span class="muted"></span>
        <v-menu
          ref="FilterEndDateBool"
          lazy
          v-model="FilterEndDateBool"
          :close-on-content-click="false"
          transition="scale-transition"
          full-width
          :nudge-right="40"
          min-width="290px"
          :return-value.sync="endDate"
        >
          <v-text-field
            slot="activator"
            :label="'End date'"
            required
            v-model="endDate"
            prepend-icon="event"
            readonly
          ></v-text-field>
          <v-date-picker
            v-model="endDate"
            class="minHeight"
            @change="
              $refs.FilterEndDateBool.save(endDate);
              searchByDate();
            "
            no-title
            scrollable
          ></v-date-picker>
        </v-menu>
      </v-flex> -->
      <v-flex xs2 pr-2>
        <v-select
          v-model="jobType"
          :items="JobListTypes"
          label="Job Type"
          persistent-hint
          @change="searchByJobType"
          single-line
        ></v-select>
      </v-flex>
      <v-flex xs2>
        <v-autocomplete
          v-model="inquiryStatus"
          :items="inquiryStatusType"
          label="Inquiry Status"
          @change="statusFilter"
          :menu-props="{ auto: true, contentClass: 'inquiry' }"
        ></v-autocomplete>
      </v-flex>
      <v-flex xs2 pr-1 pl-1>
        <v-select
          v-model="isConvertedInquiry"
          :items="inquiryTypeList"
          label="Inquiry Type"
          persistent-hint
          @change="searchByInquiryType"
          single-line
        ></v-select>
      </v-flex>

      <!-- <v-flex xs1 class="reset"></v-flex> -->
      <v-flex xs3 md2 pl-2 v-permissions="'assign-executive'">
        <div id="multiselect-sample">
          <div class="col-lg-4 control-section">
            <div class="control-styles">
              <ejs-multiselect
                id="multiselect-checkbox"
                :dataSource="executives"
                :fields="fields"
                :placeholder="checkWaterMark"
                :mode="multiMode"
                v-model="salesExecutive"
                :closePopupOnSelect="true"
                :showClearButton="false"
                :popupHeight="popHeight"
                :showDropDownIcon="showDropDownIcon"
                :showSelectAll="showSelectAll"
                :filterBarPlaceholder="filterPlaceholder"
                :enableSelectionOrder="enableSelectionOrder"
              ></ejs-multiselect>
            </div>
          </div>
        </div>

        <!-- <v-autocomplete
          v-model="salesExecutive"
          :items="executives"
          :disabled="searchStatus"
          label="Filter by Sales Executive"
          single-line
          multiple
          @click="check()"
        ></v-autocomplete> -->
      </v-flex>
      <v-flex xs1 class="reset">
        <v-btn
          class="reset-btn-color"
          flat
          prepend-icon
          color="orange darken-1"
          @click.native="reset()"
        >
          Reset
          <!-- <v-icon color="orange">donut_large</v-icon> -->
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import Vue from "vue";
import { EventBus } from "../../event-bus.js";
import ErrorBox from "@/components/Common/ErrorBox";
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import removeLocalstorageMixin from "@/mixins/removeLocalstorageMixin";
import {
  MultiSelectPlugin,
  CheckBoxSelection,
} from "@syncfusion/ej2-vue-dropdowns";
// import { ButtonPlugin } from '@syncfusion/ej2-vue-buttons';
import moment from "moment";
Vue.use(DateRangePickerPlugin);

import DateRangePicker from "vue2-daterange-picker";
import { StorageKeys, JobListTypes } from "../../constants/constants";
Vue.use(MultiSelectPlugin);

// Vue.use(ButtonPlugin);

export default {
  mixins: [removeLocalstorageMixin],
  name: "customFilterCustomer",
  components: {
    ErrorBox,
    DateRangePicker,
  },
  created() {
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = this.workingCountry =
        JSON.stringify(workingCountry);
      this.fetchExecutives();
    }
    this.removeStorage();
    localStorage.removeItem("searchSales");
    localStorage.removeItem("searchSalesType");
    localStorage.removeItem("searchex");
    localStorage.removeItem("salesJobType");
    localStorage.removeItem("startdateSales");
    localStorage.removeItem("enddateSales");
    localStorage.removeItem("payloadSales");
    // this.reset();
    this.JobListTypes = JobListTypes;

    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "sales-section") {
        this.workingCountry = JSON.stringify(val);
        if (
          localStorage.getItem("startdateSales") !== null &&
          localStorage.getItem("enddateSales") !== null
        ) {
          this.startDate = localStorage.startdateSales;
          this.endDate = localStorage.enddateSales;
          // this.searchByDate();
        }
        if (localStorage.getItem("enddateSales") !== null) {
          this.endDate = localStorage.enddateSales;
          // this.searchByDate();
        }
        if (localStorage.getItem("salesJobType") !== null) {
          this.jobType = localStorage.salesJobType;
        }
        if (localStorage.getItem("searchSales") !== null) {
          this.search = localStorage.searchSales;
          let searchSalesType = JSON.parse(localStorage.searchSalesType);
          this.searchTypes = searchSalesType;
        }
        // if (localStorage.getItem("searchex") !== null) {
        //   this.salesExecutive = localStorage.searchex;
        //   this.searchByExecutive();
        // }
        this.fetchExecutives();
      }
    });
  },
  watch: {
    salesExecutive(val) {
      if (val) {
        if (val && val.length) {
          let allSalesExecutive =
            val.length === this.executives.length ? "true" : "false";

          //localStorage.setItem("searchex", this.salesExecutive);

          this.$emit("request-executiveFilter", {
            salesExecutive: val,
            allSalesExecutive: allSalesExecutive,
          });
        } else if (!val.length) {
          this.$emit("request-executiveFilter", {
            salesExecutive: [],
            allSalesExecutive: false,
          });
        }
      }
    },
    dateRange2(val) {
      console.log(val);
      if (val) {
        if (val[0] && val[1]) {
          this.startDate = moment(val[0]).format("YYYY-MM-DD");
          this.endDate = moment(val[1]).format("YYYY-MM-DD");
        }
        console.log(this.startDate, this.endDate);
        this.$emit("request-startDateFilter", this.formatDate(this.startDate));
        this.$emit("request-endDateFilter", this.formatDate(this.endDate));
      } else {
        this.startDate = "";
        this.endDate = "";
        console.log(this.startDate, this.endDate);
        this.$emit("request-startDateFilter", this.startDate);
        this.$emit("request-endDateFilter", this.endDate);
      }
    },
  },
  data() {
    return {
      checkWaterMark: "Select Sales Executive",
      waterMark: "Select a Range",
      dateRange: { startDate: "", endDate: "" },
      dateRange2: null,
      startDate: "",
      endDate: "",

      popHeight: "350px",
      selectAll: Boolean,
      multiMode: "CheckBox",

      filterPlaceholder: "Search Executive",
      isConvertedInquiry: "",
      inquiryTypeList: [
        { text: "Converted", value: "true" },
        { text: "Pending", value: "false" },
      ],

      showSelectAll: true,
      showDropDownIcon: true,
      enableSelectionOrder: false,
      workingCountry: null,
      JobListTypes: [],
      isReset: null,
      opeartionName: "",
      searchStatus: false,
      jobType: "",
      FilterDateBool: false,
      FilterEndDateBool: false,
      name: "",
      executives: [],
      startDate: "",
      endDate: "",
      search: "",
      salesExecutive: [],
      fields: { text: "text", value: "value" },
      select: { country: "", abbr: "" },
      x: {
        error: null,
      },
      items: [
        { country: "India", abbr: "IN" },
        { country: "United Arab Emirates", abbr: "UAE" },
        { country: "USA", abbr: "USA" },
        { country: "UK", abbr: "UK" },
      ],
      inquiryStatusType: [
        { text: "Low Credit Limit", value: "Low Credit Limit" },
        { text: "Inquiry Received", value: "Inquiry Received" },
        { text: "Customer Rate Accepted", value: "Customer Rate Accepted" },
        { text: "Reopen", value: "Reopen" },
        { text: "Delayed", value: "Delay" },
        { text: "Lead Lost", value: "Lead lost" },
      ],
      inquiryStatus: "",
      searchItems: [
        {
          status: "Company/Customer Name",
          abbr: "companyName/customerName",
        },
        { status: "Inquiry ID", abbr: "inquiryId" },
        { status: "Customer Email ID", abbr: "custEmailId" },
      ],
      searchTypes: "",
    };
  },
  methods: {
    async check() {
      this.operationName = "assign-executive";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.searchByExecutive();
      } else {
        return;
      }
    },
    fetchExecutives() {
      let url = `/sales/executiveList?workingCountry=${[this.workingCountry]}`;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.axios
        .get(
          this.constants.apiUrl + url,

          config
        )
        .then(
          (response) => {
            response.data.data.forEach((e) => {
              this.executives.push({ value: e._id, text: e.name });
            });
          },
          (error) => {
            // this.x.error = "Failed to Fetch User Details";
          }
        );
    },
    reset() {
      localStorage.removeItem("searchSales");
      localStorage.removeItem("searchSalesType");
      localStorage.removeItem("searchex");
      localStorage.removeItem("salesJobType");
      localStorage.removeItem("startdateSales");
      localStorage.removeItem("enddateSales");
      localStorage.removeItem("payloadSales");
      this.startDate = "";
      this.endDate = "";
      this.inquiryStatus = "";
      this.jobType = "";
      this.select = { country: "", abbr: "" };
      this.search = "";
      this.isConvertedInquiry = "";
      this.salesExecutive = [];
      this.dateRange2 = null;
      this.startDate = "";
      this.endDate = "";
      this.searchTypes = "";
      this.$emit(
        "customer-reset",
        this.search ||
          this.jobType ||
          this.inquiryStatus ||
          this.salesExecutive ||
          this.searchTypes.abbr
      );
    },
    searchByInquiryType() {
      this.$emit("isConvertedInquiry", this.isConvertedInquiry);
    },
    filterByCountry() {
      this.$emit("customer-countryFilter", this.select.country);
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    searchByExecutive(value) {
      return;
      if (this.salesExecutive.length) {
        let allSalesExecutive =
          this.salesExecutive.length === this.executives.length
            ? "true"
            : "false";
        if (this.salesExecutive == localStorage.getItem("searchex")) {
          return;
        } else {
          localStorage.setItem("searchex", this.salesExecutive);
          this.$emit("request-executiveFilter", {
            salesExecutive: this.salesExecutive,
            allSalesExecutive: allSalesExecutive,
          });
        }
      } else {
        localStorage.setItem("searchex", this.salesExecutive);
        this.$emit("request-executiveFilter", {
          salesExecutive: this.salesExecutive,
          allSalesExecutive: false,
        });
      }
    },
    searchByJobType() {
      if (this.jobType) {
        //  localStorage.setItem("salesJobType", this.jobType);
        this.$emit("jobType", this.jobType);
      }
    },

    searchByDate() {
      if (
        this.startDate == localStorage.getItem("startdateSales") &&
        this.endDate == localStorage.getItem("enddateSales")
      ) {
        return;
      }
      if (this.endDate && this.startDate) {
        localStorage.setItem("startdateSales", this.startDate);
        localStorage.setItem("enddateSales", this.endDate);

        var start = +new Date(this.startDate);
        var end = +new Date(this.endDate);

        if (end < start) {
          this.x.error = "End Date cannot be less than Start Date.";
        } else {
          this.$emit(
            "request-startDateFilter",
            this.formatDate(this.startDate)
          );
          this.$emit("request-endDateFilter", this.formatDate(this.endDate));
        }
      } else {
        this.x.error = "Both Start Date and End Date is required.";
      }
    },
    searchAnything() {
      localStorage.setItem("searchSales", this.search);
      localStorage.setItem("searchSalesType", JSON.stringify(this.searchTypes));

      this.$emit("customer-search", this.search.trim());
      this.$emit("customer-searchType", this.searchTypes.abbr);
    },
    searchByType() {
      this.search = "";
      //  localStorage.setItem("searchSales", this.search);
      // localStorage.setItem("searchSalesType", JSON.stringify(this.searchTypes));
      this.$emit("customer-search", this.search);
      this.$emit("customer-searchType", this.searchTypes.abbr);
    },
    statusFilter() {
      this.$emit("inquiry-status", this.inquiryStatus);
    },
  },
  provide: {
    multiselect: [CheckBoxSelection],
  },
};
</script>
<style lang="scss">
div#ej2-datetimepicker_0_popup {
}
#accountDate .e-clear-icon {
  display: none !important;
  opacity: 0;
}
#accountDate ::placeholder {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54) !important;
  font-weight: 500;
}
.e-multiselect.e-input-group.e-control-wrapper.e-checkbox {
  width: 70% !important;
}
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before {
  background: darkorange !important;
}
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after {
  background: darkorange !important;
}
div.e-input-focus::before,
div.e-input-focus::after {
  background: darkorange !important;
}
.e-multi-select-wrapper .e-close-icon-hide .e-down-icon {
  background: darkorange !important;
}

.e-input-in-wrap::after {
  background: darkorange !important;
}
.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
  background-color: darkorange !important;
}
input.e-dropdownbase::placeholder {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54) !important;
  font-weight: 500;
}
.inquiry {
  max-height: 310px !important;
}
</style>
<style scoped>
.control-styles {
  margin: 0 auto;
  width: 300px;
  padding-top: 18px;
}
.minHeight {
  min-height: 300px;
}
.search {
  float: left;
}

.country {
  display: inline-block;
}

.filter {
  padding: 20px;
  margin: 0 auto;
}
.reset {
  margin-top: 10px;
}
.gap {
  margin-right: 10%;
}
.reset-btn-color {
  background-color: #ffe7c9 !important;
}

.m-t-10 {
  margin-top: 10px;
}

.filter {
  padding: 20px;
  margin: 0 auto;
}
.reset {
  margin-top: 10px;
}
.gap {
  position: relative;
  right: 2px;
  bottom: 2px;
  z-index: auto;
}
.reset-btn-color {
  background-color: #ffe7c9 !important;
}
#filter_wrap {
  display: none;
}
</style>
