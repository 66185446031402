<template>
  <v-card class="dashboard_wrap">
    <v-card-title class="bg-clr p-t-0 hide-mobile">
      <v-layout justify-space-around wrap>
        <v-avatar tile color="#ffa500" class="total avatar">
          <span class="white--text breakword"> Total Inquiries</span>
          <span class="count">{{ dashboard.totalOpportunities || "0" }}</span>
        </v-avatar>

        <v-avatar tile color="#ffa500" class="total avatar">
          <span class="white--text breakword"> Converted Inquiries</span>
          <span class="count">{{
            dashboard.convertedOpportunities || "0"
          }}</span>
        </v-avatar>
        <!--  <v-avatar tile color="#ffa500" class="total avatar">
          <span class="white--text breakword"> No. of Trucks Required</span>
          <span class="count">{{
            dashboard.numberOfAssignmentsRequired || "0"
          }}</span>
        </v-avatar> -->

        <v-avatar tile color="#ffa500" class="total avatar">
          <span class="white--text breakword"> Value Generated</span>
          <span class="count">{{ dashboard.totalValue || "0" }}</span>
        </v-avatar>
      </v-layout>
      <v-btn
        v-permissions="'add-enquiry'"
        class="white--text"
        flat
        @click="check()"
        color="orange darken-1"
      >
        <v-icon>add</v-icon>&nbsp;Add New Inquiry
      </v-btn>
      <v-btn
        class="white--text filter_btn"
        flat
        @click="showfilter()"
        color="orange darken-1"
      >
        <v-icon>filter_alt</v-icon>&nbsp;Filters
      </v-btn>
    </v-card-title>
    <SelectCountry
      :open="selectCountryDialog"
      @closeSelectCountry="selectCountryDialog = false"
      :type="'/inquiry/new'"
    />
  </v-card>
</template>
<script>
import SelectCountry from "../Common/SelectCountry";
export default {
  components: {
    SelectCountry,
  },
  props: ["dashboard"],
  data() {
    return {
      selectCountryDialog: false,
      operation: "",
      operationName: "",
      workingCountries: [],
    };
  },
  methods: {
    async check() {
      this.operationName = "add-enquiry";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        if (localStorage.getItem("workingCountries")) {
          this.workingCountries = JSON.parse(
            localStorage.getItem("workingCountries")
          );
          let workingCountry = [];
          this.workingCountries.forEach((key, index) => {
            if (key.isSelected) {
              workingCountry.push(key.value);
            }
          });
          if (workingCountry.length === 1) {
            this.$router.push(`/inquiry/new`);
          } else {
            this.selectCountryDialog = true;
          }
        }
      } else {
        return;
      }
    },
    showfilter() {
      var div = document.getElementById("filter_wrap");
      div.style.display = div.style.display == "block" ? "none" : "block";
    },
  },
};
</script>
<style scoped>
.dashboard {
  padding: 20px;
  margin: 0 auto;
}
.breakword {
  padding: 10px;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.f-16 {
  font-size: 16px !important;
}
</style>
