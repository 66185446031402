<template>
  <v-container fluid>
    <loading :active.sync="loading" />
    <v-card raised v-if="showScreen">
      <v-flex>
        <Dashboard :dashboard="dashboard" />
      </v-flex>
      <v-flex>
        <CustomFilter
          v-on:customer-search="searchText"
          v-on:customer-searchType="searchTextType"
          v-on:request-startDateFilter="startDateFilter"
          v-on:jobType="jobTypeFilter"
          v-on:request-executiveFilter="executiveFilter"
          v-on:request-endDateFilter="endDateFilter"
          v-on:isConvertedInquiry="convertedInquiry"
          v-on:customer-countryFilter="countryFilter"
          v-on:customer-reset="resetCustomer"
          v-on:inquiry-status="inquiryStatus"
        />
      </v-flex>
      <v-flex>
        <SalesList
          :dashboardData="dashboardData"
          :executiveFilter="salesExecutive"
          :convertedInquiry="isInquiry"
          :searchText="search"
          :searchTextType="filter"
          :jobTypeFilter="jobType"
          :startDateFilter="startDate"
          :endDateFilter="endDate"
          :countryFilter="country"
          :inquiryStatus="status"
          :reset="reset"
          v-on:on-block="refreshActivity"
          v-on:on-load="startLoad"
          v-on:off-load="stopLoad"
        />
      </v-flex>
    </v-card>
  </v-container>
</template>

<script>
import { StorageKeys } from "../../constants/constants";
import Dashboard from "./Dashboard";
import Swal from "sweetalert2";
import SalesList from "./SalesList";
import { CirclesToRhombusesSpinner } from "epic-spinners";
import { ScalingSquaresSpinner } from "epic-spinners";
import CustomFilter from "./CustomFilter";
import moment from "moment";
import { salesSectionApiUrl } from "@/constants/api-urls.js";
import Loading from "vue-loading-overlay";
import checkPermissionsMixin from "@/mixins/checkPermissionsMixin";
import { event } from "vue-analytics";

export default {
  mixins: [checkPermissionsMixin],
  created() {
    if (localStorage.getItem("first-load")) {
      localStorage.removeItem("first-load");
    }
    this.loading = true;
    this.$eventBus.$on("close-job-profile", () => {
      this.dialog = false;
    });
    this.$eventBus.$on("on-load", () => {
      this.loading = true;
    });

    this.$eventBus.$on("off-load", () => {
      this.loading = false;
    });

    this.fetchDashboard();
  },
  components: {
    Dashboard,
    SalesList,
    CustomFilter,
    CirclesToRhombusesSpinner,
    ScalingSquaresSpinner,
    Loading,
  },

  data: () => ({
    search: "",
    country: "",
    showScreen: true,
    isInquiry: "",
    dashboard: {
      salesValue: 0,
      totalOpportunities: 0,
      convertedOpportunities: 0,
      totalValue: 0,
      numberOfAssignmentsRequired: 0,
    },
    startDate: "",
    jobType: "",
    salesExecutive: [],
    endDate: "",
    active: "0",
    status: null,
    dialog: false,
    reset: false,
    loading: true,
    filter: "",
  }),
  methods: {
    startLoad() {
      this.loading = true;
    },
    stopLoad() {
      this.loading = false;
    },
    refreshActivity() {
      this.reset = true;
    },
    resetCustomer(event) {
      this.reset = true;
      this.search = "";
      this.isInquiry = "";
      this.jobType = "";
      this.startDate = "";
      this.status = null;
      this.endDate = "";
      this.filter = "";
      this.salesExecutive = {};
    },
    dashboardData(event) {
      this.dashboard.salesValue = event.salesValue;
      this.dashboard.totalOpportunities = event.totalOpportunities;
      this.dashboard.convertedOpportunities = event.convertedOpportunities;
      this.dashboard.totalValue = event.totalValue;
      this.dashboard.numberOfAssignmentsRequired =
        event.numberOfAssignmentsRequired;
      // this.loading = false;
    },
    convertedInquiry(event) {
      this.isInquiry = event;
      this.reset = false;
    },

    searchTextType(event) {
      this.filter = event;
      event ? (this.reset = false) : "";
    },
    inquiryStatus(event) {
      this.status = event;
      this.reset = false;
    },
    searchText(event) {
      this.search = event;
      event ? (this.reset = false) : "";
    },
    startDateFilter(event) {
      this.loading = true;
      this.startDate = event;
      this.reset = false;
    },
    jobTypeFilter(event) {
      this.loading = true;
      this.jobType = event;
      event ? (this.reset = false) : "";
    },
    executiveFilter(event) {
      //this.loading = true;
      this.salesExecutive = event;
      event.salesExecutive.length ? (this.reset = false) : "";
    },
    endDateFilter(event) {
      this.loading = true;
      this.endDate = event;
      this.reset = false;
    },
    countryFilter(event) {
      this.country = event;
      this.reset = false;
    },
    fetchDashboard() {
      this.loading = true;
      let url = salesSectionApiUrl.dashboard;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {};

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.dashboard = response.data.data;
        },
        (error) => {
          this.x.error = "Failed to update inquiry status";
        }
      );
    },
  },
};
</script>

<style>
.loader-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: block;
  opacity: 0.7;
  background-color: #fff;
  z-index: 99;
  text-align: center;
}
.loader-overlay .circles-to-rhombuses-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}
</style>
